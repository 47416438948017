import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import styles from "./projeto.module.css"
import showdown from 'showdown'

import Layout from "../components/layout"
import Container from "../components/container"

export default function Projeto({
  data,
}) {
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark
  const converter = new showdown.Converter()

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={frontmatter.description} />
        <meta property="og:description" content={frontmatter.description} />
        <meta name="image" content={frontmatter.cover} />
        <meta property="og:image" content={frontmatter.cover} />
        <title>{frontmatter.title} - Grupo Vinte</title>
        <link rel="canonical" href={`https://grupovinte.com.br${frontmatter.path}/`} />
        <html lang="pt-br" />
      </Helmet>
      <main className={styles.image} style={{ backgroundImage: `url('${frontmatter.cover}')` }}>
        <div className={styles.titleBox}>
          <h1>
            {frontmatter.title.split(' ').map((word, index) => (
              <span key={index}>{word}<br /></span>
            ))}
          </h1>
        </div>
        <div className={styles.contentBox} dangerouslySetInnerHTML={{ __html: converter.makeHtml(frontmatter.description) }} />
      </main>
      {frontmatter.about &&
        <div className={styles.content}>
          <Container>
            <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(frontmatter.about) }} />
          </Container>
        </div>
      }
      {frontmatter.images &&
        <div className={styles.portfolio}>
          {frontmatter.images.map(({image, title}, index) => (
            <div key={index} className={styles.portfolioItem}>
              <img src={image} alt={title} />
            </div>
          ))}
        </div>
      }
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        path
        type
        description
        cover
        about
        images {
          title
          image
        }
      }
    }
  }
`
